import * as React from 'react';
import { useMatch } from '@mentimeter/ragnar-device';
import type { LinkT } from '@mentimeter/ragnar-ui';
import { Box, Collapsable, List, Text } from '@mentimeter/ragnar-ui';
import { getMentiAcademyURL } from '@mentimeter/user';
import {
  ChevronDownIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  MentiKHeart,
  MentimeterLogoType,
  TwitterIcon,
  YoutubeIcon,
} from '@mentimeter/ragnar-visuals';
import { useTranslation } from 'react-i18next';
import { openPreferenceCenter } from '@mentimeter/onetrust';
import { useDevelopmentToolsModal } from '@mentimeter/development-tools/hooks';
import { H3 } from 'src/ui/typography';
import { Clickable, Link } from '../ui/actions';
import { Section, Wide } from '../ui/layout';
import { FooterSwitchLanguage } from './FooterSwitchLanguage';

const ONETRUST_DOMAIN_SCRIPT = globalThis.__mentimeterEnv['NEXT_PUBLIC_ONETRUST_DOMAIN_SCRIPT'];

interface FooterItem {
  heading: string;
  links: LinkT[];
}

// NB Remember to update static 404 page as well.
const DefaultFooterItems = (): FooterItem[] => {
  const { t } = useTranslation('common');

  const items: FooterItem[] = [
    {
      heading: t('common:page_links.features'),
      links: [
        {
          children: t('common:page_links.overview'),
          href: '/features',
        },
        {
          children: t('common:page_links.ai_builder'),
          href: '/features/ai-builder',
        },
        {
          children: t('common:page_links.live_polling'),
          href: '/features/live-polling',
        },
        {
          children: t('common:page_links.word_cloud'),
          href: '/features/word-cloud',
        },
        {
          children: t('common:page_links.quiz'),
          href: '/features/quiz-presentations',
        },
        {
          children: t('common:page_links.q&a'),
          href: '/features/live-questions-and-answers',
        },
        {
          children: t('common:page_links.surveys'),
          href: '/features/surveys',
        },
        {
          children: t('common:page_links.presentation_maker'),
          href: '/features/presentation-maker',
        },
      ],
    },
    {
      heading: t('common:page_links.resources'),
      links: [
        {
          children: t('common:page_links.blog'),
          href: '/blog',
        },
        {
          children: t('common:page_links.how_to'),
          href: '/how-to',
        },
        {
          children: t('common:page_links.work'),
          href: '/work',
        },
        {
          children: t('common:page_links.education'),
          href: '/education',
        },
        {
          children: t('common:page_links.templates'),
          href: '/templates',
        },
        {
          children: 'Academy',
          href: getMentiAcademyURL(
            '?utm_source=mentimeter.com&utm_medium=referral&utm_campaign=footer',
          ),
          target: '_blank',
          rel: 'noopener noreferrer',
        },
        {
          children: t('common:page_links.webinars'),
          href: '/webinars',
        },
        {
          children: t('common:page_links.comparison'),
          href: '/comparison',
        },
        {
          children: t('common:page_links.stories'),
          href: '/stories',
        },
        {
          children: t('common:page_links.integrations'),
          href: '/integrations',
        },
      ],
    },
    {
      heading: t('common:page_links.details'),
      links: [
        {
          children: t('common:page_links.legal'),
          href: '/trust',
        },
        {
          children: t('common:page_links.policies'),
          href: '/trust/legal/privacy-policy',
        },
        {
          children: t('common:page_links.accessibility'),
          href: '/accessibility',
        },
        {
          children: t('common:page_links.help_center'),
          href: 'https://help.mentimeter.com/',
          target: '_blank',
          rel: 'noopener noreferrer',
        },
        {
          children: t('common:page_links.requirements'),
          href: '/requirements',
        },
        {
          children: t('common:page_links.ai_at_mentimeter'),
          href: '/ai-at-mentimeter',
        },
      ],
    },
    {
      heading: t('common:page_links.about_us'),
      links: [
        {
          children: t('common:page_links.press_info'),
          href: '/press',
        },
        {
          children: t('common:page_links.the_team'),
          href: '/the-team',
        },
        {
          children: t('common:page_links.jobs'),
          href: '/jobs',
        },
        {
          children: t('common:page_links.culture'),
          href: '/culture',
        },
        {
          children: t('common:page_links.benefits'),
          href: '/benefits',
        },
        {
          children: t('common:page_links.contact_us'),
          href: '/contact',
        },
        {
          children: t('common:page_links.climate'),
          href: '/climate',
        },
        {
          children: t('common:page_links.investors'),
          href: '/investors',
        },
      ],
    },
  ];

  if (ONETRUST_DOMAIN_SCRIPT) {
    items[2]?.links.push({
      children: t('common:page_links.open_cookie_preferences'),
      onClick: openPreferenceCenter,
    });
  }

  return items;
};

const PageFooter = () => {
  const { tryOpenDevelopmentToolsModal } = useDevelopmentToolsModal();

  const isMobile = useMatch({ lessThan: 3 });
  const [showMenu, setShowMenu] = React.useState<string>();

  const openSelectedMenu = (heading: string) => {
    setShowMenu(showMenu === heading ? undefined : heading);
  };

  const { t } = useTranslation('common');
  const translations = DefaultFooterItems();

  if (isMobile) {
    return (
      <Section
        theme={{ colors: 'plain' }}
        as="footer"
        justifyContent="center"
        bg="bg"
      >
        <Wide
          alignItems={['stretch', 'center']}
          flexDirection="column"
          mx="auto"
        >
          <Box my={4} alignItems="flex-start" width="170px" height="23px">
            <MentimeterLogoType />
          </Box>
          <Box
            alignItems="stretch"
            flexDirection={['column', 'row']}
            width={['100%', 4 / 6]}
            justifyContent="space-around"
          >
            {translations.map(({ heading, links }) => (
              <Box key={heading} mb={[3, 0]} alignItems="stretch">
                <Clickable
                  onClick={() => openSelectedMenu(heading)}
                  flexDirection="row"
                  aria-expanded={heading === showMenu}
                >
                  <Box
                    flexDirection="row"
                    justifyContent="space-between"
                    borderColor="text"
                    borderBottomWidth="1px"
                    borderStyle="solid"
                    width="100%"
                  >
                    <Text fontWeight="semiBold">{heading}</Text>
                    <Box
                      mt={1}
                      extend={() => ({
                        transition: 'transform 0.2s ease',
                        transform:
                          showMenu === heading
                            ? 'rotate(-180deg)'
                            : 'rotate(0)',
                      })}
                    >
                      <ChevronDownIcon color="text" size={2} />
                    </Box>
                  </Box>
                </Clickable>
                {showMenu === heading && (
                  <Collapsable show={heading === showMenu} width="100%">
                    <List>
                      {links.map(({ href, children, ...rest }, i) => (
                        <Box as="li" key={href || 'footerButton_' + i}>
                          <Link
                            fontSize={2}
                            {...rest}
                            mb={2}
                            color="textWeak"
                            tabIndex={showMenu ? 0 : -1} // If menu is hidden, links should not be focusable
                            underline={false}
                            href={href}
                            extend={() => ({
                              '&:first-child': {
                                marginTop: '16px',
                              },
                            })}
                          >
                            {children}
                          </Link>
                        </Box>
                      ))}
                    </List>
                  </Collapsable>
                )}
              </Box>
            ))}
          </Box>
          <FooterSwitchLanguage />
          <Box
            width={['100%', 1 / 6]}
            justifyContent="flex-end"
            alignItems="center"
            mb={2}
          >
            <Box
              width="60px"
              height="60px"
              onClick={tryOpenDevelopmentToolsModal}
            >
              <MentiKHeart />
            </Box>
            <Box
              flexDirection="row"
              mt={3}
              mb={[4, 0]}
              justifyContent="center"
              width="100%"
            >
              <Clickable
                aria-label={t('common:page_links.facebook_aria')}
                href="https://www.facebook.com/Mentimeter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon isWrappedInLink={true} size={4} />
              </Clickable>
              <Clickable
                aria-label={t('common:page_links.linkedin_aria')}
                href="https://www.linkedin.com/company/mentimeter"
                target="_blank"
                rel="noopener noreferrer"
                ml={3}
              >
                <LinkedInIcon isWrappedInLink={true} size={4} />
              </Clickable>
              <Clickable
                aria-label={t('common:page_links.youtube_aria')}
                href="https://www.youtube.com/@Mentimeter"
                target="_blank"
                rel="noopener noreferrer"
                ml={3}
              >
                <YoutubeIcon isWrappedInLink={true} size={4} />
              </Clickable>
              <Clickable
                aria-label={t('common:page_links.instagram_aria')}
                href="https://instagram.com/Mentimeter"
                target="_blank"
                rel="noopener noreferrer"
                ml={3}
              >
                <InstagramIcon isWrappedInLink={true} size={4} />
              </Clickable>
              <Clickable
                aria-label={t('common:page_links.twitter_aria')}
                href="https://twitter.com/Mentimeter"
                target="_blank"
                rel="noopener noreferrer"
                ml={3}
              >
                <TwitterIcon isWrappedInLink={true} size={4} />
              </Clickable>
            </Box>
          </Box>
        </Wide>
      </Section>
    );
  } else {
    return (
      <Section
        theme={{ colors: 'plain' }}
        as="footer"
        justifyContent="center"
        bg="bg"
      >
        <Wide alignItems="stretch" flexDirection="row" mx="auto">
          <Box width={1 / 5} alignItems="center" justifyContent="space-between">
            <Box width="170px" height="23px">
              <MentimeterLogoType />
            </Box>
            <Box
              justifyContent="flex-end"
              alignItems="center"
              mb={2}
              width="100%"
            >
              <Box
                width="60px"
                height="60px"
                onClick={tryOpenDevelopmentToolsModal}
              >
                <MentiKHeart />
              </Box>
              <Box
                borderColor="text"
                borderBottomWidth="1px"
                borderStyle="solid"
              >
                <Box
                  flexDirection="row"
                  justifyContent="stretch"
                  width="100%"
                  my={3}
                >
                  <Clickable
                    aria-label={t('common:page_links.facebook_aria')}
                    href="https://www.facebook.com/Mentimeter"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookIcon isWrappedInLink={true} size={4} />
                  </Clickable>
                  <Clickable
                    aria-label={t('common:page_links.linkedin_aria')}
                    href="https://www.linkedin.com/company/mentimeter"
                    target="_blank"
                    rel="noopener noreferrer"
                    ml={3}
                  >
                    <LinkedInIcon isWrappedInLink={true} size={4} />
                  </Clickable>
                  <Clickable
                    aria-label={t('common:page_links.youtube_aria')}
                    href="https://www.youtube.com/@Mentimeter"
                    target="_blank"
                    rel="noopener noreferrer"
                    ml={3}
                  >
                    <YoutubeIcon isWrappedInLink={true} size={4} />
                  </Clickable>
                  <Clickable
                    aria-label={t('common:page_links.instagram_aria')}
                    href="https://instagram.com/Mentimeter"
                    target="_blank"
                    rel="noopener noreferrer"
                    ml={3}
                  >
                    <InstagramIcon isWrappedInLink={true} size={4} />
                  </Clickable>
                  <Clickable
                    aria-label={t('common:page_links.twitter_aria')}
                    href="https://twitter.com/Mentimeter"
                    target="_blank"
                    rel="noopener noreferrer"
                    ml={3}
                  >
                    <TwitterIcon isWrappedInLink={true} size={4} />
                  </Clickable>
                </Box>
              </Box>
              <FooterSwitchLanguage />
            </Box>
          </Box>
          <Box
            alignItems="stretch"
            flexDirection="row"
            width={4 / 5}
            justifyContent="space-around"
          >
            {translations.map(({ heading, links }) => (
              <Box key={heading}>
                <Box
                  borderColor="text"
                  borderBottomWidth="1px"
                  borderStyle="solid"
                  width="100%"
                  mb={3}
                >
                  <H3
                    color="textWeak"
                    fontSize={['100']}
                    mb={0}
                    lineHeight="normal"
                    fontWeight="semiBold"
                  >
                    {heading}
                  </H3>
                </Box>

                <List>
                  {links.map(({ href, children, ...rest }, i) => (
                    <Box as="li" key={href || 'footerButton_' + i}>
                      <Link
                        fontSize={2}
                        {...rest}
                        mb={2}
                        color="textWeak"
                        underline={false}
                        href={href}
                      >
                        {children}
                      </Link>
                    </Box>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
        </Wide>
      </Section>
    );
  }
};

export default PageFooter;
