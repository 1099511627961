import React, { useCallback, useState } from 'react';
import { Box, Button, Form, TextInputItem } from '@mentimeter/ragnar-ui';
import { gtmTrack } from '@mentimeter/google-tracking';
import { useTranslation } from 'react-i18next';
import { CrossIcon } from '@mentimeter/ragnar-visuals';
import { getRegionByVoteId } from '@mentimeter/region';
import { trackVisitor } from 'src/trackVisitor';
import { core } from '@mentimeter/http-clients';
import { trackUser } from '@api/tracking/client';
import {
  gray1100,
  gray200,
  gray600,
  purple100,
  purpleDark600,
  purpleDark800,
  whiteBase,
} from '@mentimeter/ragnar-colors';
import { Narrow } from '../layout';
import { Clickable } from '../actions';
import { P } from '../typography';

const VOTING_URL = globalThis.__mentimeterEnv['NEXT_PUBLIC_VOTING_URL'];

export type VotingBarContext = 'Justvoted' | 'Homepage' | 'Signup';

interface Props {
  context: VotingBarContext;
}

export const VotingBarV2 = ({ context }: Props) => {
  const [state, setState] = useState<undefined | 'error' | 'loading'>();
  const { t } = useTranslation('common');
  const votingBarRef = React.useRef<HTMLDivElement | null>(null);
  const [hideVotingBar, setHideVotingBar] = useState(false);

  const [rawVoteCode, setRawVoteCode] = React.useState<string>('');
  const voteId = rawVoteCode.replace(/\s/g, '');
  const isValidVotingId = voteId.length >= 4;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    void setRawVoteCode(event.target.value);

  const submitVoteCode = useCallback(
    async (voteId: string) => {
      setState('loading');
      try {
        const region = getRegionByVoteId(voteId);

        const { data, status } = await core({ region }).getSeriesByVoteId(
          voteId,
        );

        if (!data?.vote_key) {
          throw new Error('series missing in result');
        }

        if (status === 200) {
          gtmTrack({
            event: 'joinPresentation',
            type: data.closed_for_voting ? 'closed' : 'success',
          });

          trackVisitor({
            event: 'Clicked voting bar button',
            properties: { context, status: 'success' },
          });
          trackUser({
            event: 'Clicked voting bar button',
            properties: { context, status: 'success' },
          });
          window.location.assign(`${VOTING_URL}/${data.vote_key}`);
        }
      } catch {
        gtmTrack({
          event: 'joinPresentation',
          type: 'fail',
        });

        trackVisitor({
          event: 'Clicked voting bar button',
          properties: { context, status: 'error' },
        });
        setState('error');
        setTimeout(() => setState(undefined), 2000);
      }
    },
    [context],
  );

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>): void => {
      event.preventDefault();
      if (isValidVotingId) {
        submitVoteCode(voteId);
      }
    },
    [isValidVotingId, voteId, submitVoteCode],
  );

  if (hideVotingBar) return null;

  return (
    <Box
      width="100%"
      height={['98px', '60px']}
      bg={purple100}
      borderBottomLeftRadius="2xl"
      borderBottomRightRadius="2xl"
    >
      <Box
        width="100%"
        height="60px"
        alignItems="center"
        justifyContent="center"
        px={[4, null, null, 6]}
        ref={votingBarRef}
        zIndex={9}
      >
        <Narrow alignItems="center" justifyContent="center">
          <Form
            display="flex"
            data-testid="enter-code-to-vote-form"
            mt={[4, 0]}
            p={3}
            flexDirection={['column', 'row']}
            alignItems="center"
            justifyContent="center"
            onSubmit={onSubmit}
          >
            <P
              fontSize="112.5"
              lineHeight="body"
              mb={[2, 0]}
              mr={[0, 3]}
              textAlign="center"
              color={purpleDark800}
              extend={() => ({
                whiteSpace: 'nowrap',
              })}
            >
              Enter code to join a live Menti
            </P>
            <Box flexDirection="row">
              <TextInputItem
                aria-label={t('common:voting_bar_header.enter_code_here')}
                data-testid="enter-code-to-vote-input"
                type="tel"
                value={rawVoteCode}
                name="id"
                id="enter-vote-key"
                placeholder="1234 5678"
                onChange={handleChange}
                mr={2}
                maxLength={12} // You can enter a max of 12 chars ("12 34 56 78 ")
                inputSize="compact"
                extend={() => ({
                  border: '1px solid #DBDCE1',
                  boxShadow: 'none !important',
                  backgroundColor: whiteBase,
                  maxWidth: '104px',
                  textAlign: 'center',
                  ':focus': {
                    border: '2px solid ' + purpleDark600,
                  },
                })}
              />
              <Button
                data-testid="enter-code-to-vote-button"
                aria-label="Continue"
                type="submit"
                disabled={!isValidVotingId}
                state={state}
                variant="primary"
                extend={() => ({
                  ':disabled': {
                    cursor: 'default',
                    background: whiteBase,
                    color: gray1100,
                  },
                  ':hover:disabled': {
                    background: gray200,
                    color: gray600,
                  },
                })}
              >
                {t('common:voting_bar_header.join')}
              </Button>
            </Box>
          </Form>
        </Narrow>
        <Clickable
          display={['none', null, 'initial']}
          position="absolute"
          right="2rem"
          aria-label={t('common:page_links.close_menu')}
          onClick={() => setHideVotingBar(true)}
        >
          <CrossIcon size={3} />
        </Clickable>
      </Box>
    </Box>
  );
};
