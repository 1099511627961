const PHASE = globalThis.__mentimeterEnv['NEXT_PUBLIC_PHASE'];

export { PHASE };

export enum PHASES {
  LOCAL = 'local',
  CI = 'ci',
  REVIEW = 'review',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export function isLocalPhase(): boolean {
  return PHASE === PHASES.LOCAL;
}

export function isCIPhase(): boolean {
  return PHASE === PHASES.CI;
}

export function isReviewPhase(): boolean {
  return PHASE === PHASES.REVIEW;
}

export function isStagingPhase(): boolean {
  return PHASE === PHASES.STAGING;
}

export function isProductionPhase(): boolean {
  return PHASE === PHASES.PRODUCTION;
}
