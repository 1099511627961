'use client';
import * as React from 'react';
import { Box, Text } from '@mentimeter/ragnar-ui';
import { Link } from '../ui/actions';
import Header from '../ui/header';
import Footer from './Footer';
import Head from './Head';
import OutdatedBrowser from './OutdatedBrowser';
import NoScript from './NoScript';

type Props = Omit<
  React.ComponentProps<typeof Head>,
  'siteUrl' | 'pathname' | 'image' | 'title'
> & {
  title?: string | undefined;
  headerElement?: React.ReactNode;
  footerElement?: React.ReactNode;
  onlyRenderOnClient?: boolean;
  renderOgUrl?: boolean | undefined;
  image?: string | undefined;
  fullHeight?: boolean;
  children: React.ReactNode;
};

const ORIGIN = globalThis.__mentimeterEnv['NEXT_PUBLIC_ORIGIN'] as string;

function Page({
  onlyRenderOnClient,
  title,
  description,
  image,
  renderOgUrl,
  fullHeight,
  headerElement = <Header />,
  footerElement = <Footer />,
  children,
}: Props) {
  const [readyToRenderContent, setReadyToRenderContent] = React.useState(
    !onlyRenderOnClient,
  );
  const [isOutdatedBrowser, setIsOutdatedBrowser] = React.useState(false);

  React.useEffect(() => {
    // set these flags on client
    setReadyToRenderContent(true);
    setIsOutdatedBrowser(OutdatedBrowser(navigator.userAgent));
  }, []);

  if (isOutdatedBrowser) {
    return (
      <Text m="auto" p={6} textAlign="center">
        Hi, we&apos;re sorry to let you know that Mentimeter doesn&apos;t
        support your browser. Please
        <Link
          href="http://outdatedbrowser.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          update your browser
        </Link>
        , or try using another one.
      </Text>
    );
  }

  return (
    <>
      <NoScript />
      <Head
        description={description}
        image={image}
        title={title}
        siteUrl={ORIGIN}
        renderOgUrl={renderOgUrl}
      />
      {fullHeight ? (
        <Box
          minHeight="100vh"
          flexDirection="column"
          alignItems="stretch"
          justifyContent="stretch"
        >
          {headerElement}
          <Box
            flex="1"
            flexDirection="column"
            alignItems="stretch"
            justifyContent="stretch"
          >
            {readyToRenderContent && children}
          </Box>
          {footerElement}
        </Box>
      ) : (
        <>
          {headerElement}
          {readyToRenderContent && children}
          {footerElement}
        </>
      )}
    </>
  );
}

export default Page;
