import React from 'react';
import type { LabelAccessibilityT, Rule } from '@mentimeter/ragnar-styled';
import { styled } from '@mentimeter/ragnar-styled';
import { combineRules } from '@mentimeter/ragnar-react';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { styledTextRule, type TextT } from '../Text/Text';

export interface LinkT extends TextT, LabelAccessibilityT {
  href?: string | undefined;
  rel?: string | undefined;
  target?: string | undefined;
  underline?: boolean | undefined;
  truncate?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
}

const rule: Rule<LinkT> = ({ theme }) => ({
  borderRadius: `${theme.radii[1]}px`,
  ':hover': {
    opacity: theme.opacity.faded,
  },
  ':focus': {
    boxShadow: `0 0 0 1px ${theme.colors.primary}`,
  },
  ':focus:not(:focus-visible)': {
    boxShadow: 'none',
  },
  ':focus-visible': {
    boxShadow: `0 0 0 1px ${theme.colors.primary}`,
  },
  ':active': {
    opacity: theme.opacity.faded,
  },
  cursor: 'pointer',
});

export const FelaLink = styled('a', {
  displayName: 'Link',
})(
  combineRules(styledTextRule, rule),
  'color',
  'letterSpacing',
  'textAlign',
  'kosmosLineHeight',
  'kosmosSpacing',
  'kosmosFontSize',
  'maxWidth',
  'width',
  'display',
  'overflow',
  'flexbox',
  'position',
) as React.FC<LinkT>;

export const Link = React.forwardRef(
  (props: React.ComponentProps<typeof FelaLink>, ref) => {
    const { truncate, underline = true, className, ...rest } = props;

    const truncateClasses = [
      'text-ellipsis',
      'whitespace-nowrap',
      'overflow-hidden',
      'block',
      'max-w-full',
    ];

    const defaultClasses = [
      'r-link', // Reset selector
      underline ? 'underline' : 'no-underline',
      'text-primary',
      'font-body',
      'leading-normal',
      'break-words',
      'text-size-inherit',
    ];
    if (truncate) defaultClasses.push(...truncateClasses);

    return (
      <FelaLink {...rest} ref={ref} className={cn(defaultClasses, className)} />
    );
  },
);
