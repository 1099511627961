export const DEFAULT_TITLE = 'Interactive presentation software';
export const DEFAULT_DESCRIPTION = `Interact with your audience using real-time voting. No installations or downloads required - and it's free!`;
export const DEFAULT_IMAGE =
  'https://static.mentimeter.com/static/images/mentimeter-og.png';
export const FAVICON_SIZES = [16, 32, 96, 192];
export const APPLE_TOUCH_ICON_SIZES = [57, 60, 72, 76, 114, 120, 144, 152, 180];

export function buildFaviconHref(size: number): string {
  return `https://static.mentimeter.com/assets/icon/favicon-${size}x${size}.png?v=2`;
}

export function buildAppleTouchIconHref(size: number): string {
  return `https://static.mentimeter.com/assets/icon/apple-touch-icon-${size}x${size}.png?v=2`;
}

export function getFavIcons() {
  return FAVICON_SIZES.map((size) => ({
    type: 'image/png',
    url: buildFaviconHref(size),
    sizes: `${size}x${size}`,
  }));
}

export function getAppleTouchIcons() {
  return APPLE_TOUCH_ICON_SIZES.map((size) => ({
    type: 'image/png',
    url: buildAppleTouchIconHref(size),
    sizes: `${size}x${size}`,
  }));
}

export function getTwitterMetadata({
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION,
  image = DEFAULT_IMAGE,
} = {}) {
  return {
    title,
    description,
    image,
    card: 'summary_large_image',
    site: '@Mentimeter',
    creator: '@Mentimeter',
  };
}
