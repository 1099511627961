import React from 'react';

const REGISTRATION_HOMEPAGE = 'homepage';

export function getReferral(pathname: string, search: string): string {
  const params = new URLSearchParams(search);
  const referral = params.get('referral');

  if (pathname === '/' || referral === '') {
    return REGISTRATION_HOMEPAGE;
  }

  // Remove the first slash, so we don't ruin data for the old paths
  // that only contained the first sub-path without any slashes
  return referral ?? (pathname || '').split('/').filter(Boolean).join('/');
}

export function useReferral() {
  // return empty string for server side rendering
  const [referral, setReferral] = React.useState('');

  React.useEffect(() => {
    // get window location on client side rendering
    setReferral(getReferral(window.location.pathname, window.location.search));
  }, []);

  return referral;
}
