import * as React from 'react';
import type { LinkT } from '@mentimeter/ragnar-ui';
import { Link } from '../actions';

type NavLinkT = LinkT & {
  active?: boolean;
};

export const NavLink = ({ active = false, ...linkProps }: NavLinkT) => {
  return (
    <Link
      fontWeight="semiBold"
      underline={false}
      fontSize={2}
      {...linkProps}
      color={active ? 'primary' : 'text'}
      extend={({ theme }) => ({
        opacity: 1,
        whiteSpace: 'nowrap',
        ':hover': {
          color: theme.colors.primary,
          textDecoration: 'none',
          opacity: 1,
        },
      })}
    />
  );
};
