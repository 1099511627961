import * as React from 'react';
import { Box, type BoxT, Text, type TextT } from '@mentimeter/ragnar-ui';
import { Image, Link } from '@mentimeter/ragnar-ui';
import { slugify } from 'src/components/AnchorHeading';
import { getTextContent } from 'src/cms/DocumentRenderer';
import { LinkIcon } from '@mentimeter/ragnar-visuals';

interface HeadingT extends TextT {
  useAnchors?: boolean;
}

const Heading = (props: HeadingT) => {
  const { children, useAnchors } = props;
  if (!children) {
    return null;
  }

  const id = slugify(getTextContent(children));

  const generalStyles: TextT = {
    as: 'h2',
    color: 'text',
    fontFamily: 'heading',
    lineHeight: 'heading',
    fontSize: [5, 6, 7],
    mb: [2, null, 3],
  };

  if (useAnchors) {
    return (
      <Text
        {...generalStyles}
        id={id}
        extend={() => ({
          scrollMarginTop: '130px',
          '> .heading-anchor': {
            display: 'none',
          },
          ':hover': {
            '> .heading-anchor': {
              display: 'initial',
            },
          },
        })}
        {...props}
      >
        {children}
        <Link
          lineHeight="none"
          underline={false}
          aria-hidden="true"
          className="heading-anchor"
          href={`#${id}`}
          mx={2}
        >
          <LinkIcon size={16} />
        </Link>
      </Text>
    );
  }

  return (
    <Text
      {...generalStyles}
      id={id}
      extend={() => ({ scrollMarginTop: '130px' })}
      {...props}
    />
  );
};

// Use as the main heading on pages without a Hero
export const H1 = (props: TextT) => (
  <Heading as="h1" fontSize={[5, 7, 7, 8]} {...props} />
);

// Use in longer text content, e.g blog posts
export const H2 = (props: TextT) => (
  <Heading
    as="h2"
    mb={['space4', 'space6']}
    fontSize={['175', '200', '225', '225']}
    {...props}
  />
);

// Use in longer text content, e.g blog posts and secondary headings in blocks
export const H3 = (props: HeadingT) => (
  <Heading
    as="h3"
    fontSize={['125', null, null, '175']}
    useAnchors={false}
    {...props}
  />
);

// Use in longer text content, e.g blog posts
export const H4 = (props: TextT) => <Heading as="h4" fontSize={3} {...props} />;

// Use below Headings
export const Subheading = (props: TextT) => (
  <Text as="p" fontSize={[3, null, null, 4]} {...props} />
);

export const P = (props: TextT) => (
  <Text as="p" fontSize={[2, 3]} mb={[2, 4]} {...props} />
);

export const Bold = (props: TextT) => (
  <Text
    as="span"
    fontWeight="semiBold"
    fontSize="inherit"
    lineHeight="inherit"
    color="inherit"
    {...props}
  />
);

export const Italic = (props: TextT) => (
  <Text
    as="span"
    fontStyle="italic"
    fontSize="inherit"
    lineHeight="inherit"
    color="inherit"
    {...props}
  />
);

export const Meta = (props: TextT) => (
  <Text as="p" color="textWeaker" fontSize={1} lineHeight="body" {...props} />
);

export const Badge = (props: TextT) => (
  <Text
    color="primary"
    fontSize={2}
    textTransform="uppercase"
    {...props}
    extend={() => ({
      letterSpacing: '4px',
    })}
  />
);

interface OrderedListItemWithImageT extends BoxT {
  index: number;
  src: string;
}

export const OrderedListItemWithImage = ({
  index,
  children,
  src,
  ...rest
}: OrderedListItemWithImageT) => (
  <Box
    as="li"
    flexDirection="column"
    alignItems="center"
    width={[1 / 3]}
    px={3}
    {...rest}
  >
    <Image src={src} alt="" mb={4} width="90%" aria-hidden="true" />
    <Box flexDirection="row">
      <Text fontSize={7} fontWeight="semiBold" color="positive" mr={3}>
        {index}
      </Text>
      <P mb={0}>{children}</P>
    </Box>
  </Box>
);

interface OrderedListItemT extends BoxT {
  index: number;
}

export const OrderedListItem = ({
  index,
  children,
  ...rest
}: OrderedListItemT) => (
  <Box as="li" flexDirection="row" alignItems="center" {...rest}>
    <Text fontSize={7} fontWeight="semiBold" color="positive" mr={3}>
      {index}
    </Text>
    <P mb={0}>{children}</P>
  </Box>
);

interface ParagraphOrderedListItemT extends BoxT {
  index: number;
}

export const ParagraphOrderedListItem = ({
  index,
  children,
  ...rest
}: ParagraphOrderedListItemT) => (
  <Box
    as="li"
    flexDirection="row"
    alignItems="stretch"
    mb={3}
    extend={() => ({
      '&:last-child': {
        marginBottom: 0,
      },
    })}
    {...rest}
  >
    <Text fontSize={3} fontWeight="semiBold">
      §{index}
    </Text>
    <P mb={0}>{children}</P>
  </Box>
);
