import { type KeyboardEvent, useCallback, useState } from 'react';
import { gtmTrack } from '@mentimeter/google-tracking';
import { Box, Clickable, Text, Link, Collapsable } from '@mentimeter/ragnar-ui';
import { ChevronDownIcon, GlobeIcon } from '@mentimeter/ragnar-visuals';
import { usePathname } from '@mentimeter/next-navigation';
import { useHasMounted } from '@mentimeter/react-hooks';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LOCALE } from 'src/constants.cjs';
import { Wide } from '../ui/layout';
import { setUserLocale } from '../utils/set-user-locale';
import { getPathnameWithoutLocale } from '../utils/getRouterLocale';

const LANGUAGES = [
  { title: 'English', locale: 'en-US' },
  { title: 'German', locale: 'de-DE' },
  { title: 'Portuguese (Brazil)', locale: 'pt-BR' },
  { title: 'Spanish', locale: 'es-ES' },
];

export function FooterSwitchLanguage() {
  const { i18n } = useTranslation();
  const { changeLanguage } = i18n;
  const hasMounted = useHasMounted();
  const pathname = usePathname();

  const [showMenu, setShowMenu] = useState(false);
  const pathnameWithoutLocale = getPathnameWithoutLocale(pathname);

  const switchLanguage = useCallback(
    (nextLocale: string, title: string) => {
      setUserLocale(nextLocale);

      gtmTrack({
        event: 'choseLanguage',
        type: title,
      });

      changeLanguage(nextLocale);
    },
    [changeLanguage],
  );

  const handleKeyPress = useCallback(
    (locale: string, title: string) => (e: KeyboardEvent) => {
      e.stopPropagation();
      if (e.key === 'Enter') {
        switchLanguage(locale, title);
      }
    },
    [switchLanguage],
  );

  return (
    <Wide
      flex={1}
      alignItems={['stretch', 'center']}
      flexDirection="column"
      mt={3}
      mb={4}
    >
      <Box alignItems={['stretch', 'center']}>
        <Box
          my={1}
          alignItems="stretch"
          borderColor="text"
          borderBottomWidth="1px"
          borderStyle="solid"
          mb={showMenu ? '16px' : '0px'}
          extend={() => ({
            '@media screen and (min-width: 961px)': {
              borderBottomWidth: '0px',
            },
          })}
        >
          <Clickable
            onClick={() => setShowMenu(!showMenu)}
            flexDirection="row"
            justifyContent="space-between"
            aria-expanded={showMenu}
          >
            <Box flexDirection="row">
              <GlobeIcon size={3} />
              <Text fontWeight="semiBold" ml={2}>
                Choose your language
              </Text>
            </Box>
            <Box
              mt={1}
              extend={() => ({
                transition: 'transform 0.2s ease',
                transform: showMenu ? 'rotate(-180deg)' : 'rotate(0)',
              })}
            >
              <ChevronDownIcon color="text" size={2} />
            </Box>
          </Clickable>
        </Box>
        <Collapsable show={showMenu} width="100%">
          {hasMounted
            ? // render this only on a client otherwise it give a warn about diff in server /en-US/pathname and /pathname.
              // Also, it's not visible anyway
              LANGUAGES.map(({ title, locale }) => (
                <Box mb={2} key={locale}>
                  <Link
                    fontSize={2}
                    color="textWeak"
                    tabIndex={showMenu ? 0 : -1} // If menu is hidden, links should not be focusable
                    underline={false}
                    href={
                      locale === DEFAULT_LOCALE
                        ? pathnameWithoutLocale
                        : `/${locale}${pathnameWithoutLocale}`
                    }
                    onClick={() => switchLanguage(locale, title)}
                    onKeyUp={handleKeyPress(locale, title)}
                  >
                    {title}
                  </Link>
                </Box>
              ))
            : null}
        </Collapsable>
      </Box>
    </Wide>
  );
}
