import * as React from 'react';
import type { Document } from '@contentful/rich-text-types';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import {
  documentToReactComponents,
  type Options,
} from '@contentful/rich-text-react-renderer';
import { Text } from '@mentimeter/ragnar-ui';
import { Bold, Italic, H2 } from '../ui/typography';
import { Link } from '../ui/actions';

const options: Options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Text
        mb={[2, 3]}
        extend={() => ({
          ':empty': { display: 'none' },
        })}
      >
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_2]: (_, children) => <H2 mb={3}>{children}</H2>,
    [INLINES.HYPERLINK]: (node, children) => (
      <Link href={node.data.uri}>{children}</Link>
    ),
  },
};

interface Props {
  document: Document;
}

export function CalloutRenderer({ document }: Props): any {
  return documentToReactComponents(
    {
      ...document,
      content: document.content.map((x) => ({
        ...x,
        id: x.data.target?.sys.contentType.sys.id,
      })),
    },
    options,
  );
}
