import * as React from 'react';
import type { BoxT } from '@mentimeter/ragnar-ui';
import type { SpringValues } from 'react-spring';
import { useSpring, animated } from 'react-spring';
import { Box } from '../Box';

export interface CollapsableT extends BoxT {
  children: React.ReactNode;
  show: boolean;
  noAnimation?: boolean;
}

export const Collapsable = ({
  show,
  noAnimation = false,
  children,
  ...props
}: CollapsableT) => {
  const [height, setHeight] = React.useState<number>(0);
  const style = useSpring({
    height: show ? height : 0,
    opacity: show ? 1 : 0,
    overflow: show ? 'visible' : 'hidden',
  });
  const resizeObserver = React.useMemo<ResizeObserver | undefined>(
    () =>
      /// Ensure we're not trying to access window unless it is defined.
      typeof window !== 'undefined' && window.ResizeObserver
        ? new ResizeObserver((entries) => {
            for (const entry of entries) setHeight(entry.contentRect.height);
          })
        : undefined,
    [],
  );
  const setMeasuredHeight = React.useCallback(
    (node: Element | null) => {
      if (node === null) return;
      resizeObserver?.observe(node);
      setHeight(node.getBoundingClientRect().height);
    },
    [resizeObserver],
  );

  React.useEffect(
    () => () => {
      resizeObserver?.disconnect();
    },
    [resizeObserver],
  );

  return (
    <Box
      as={noAnimation ? 'div' : animated.div}
      {...props}
      overflow="hidden"
      style={style as SpringValues}
      position="relative"
      aria-hidden={!show} // Hide from screen readers when collapsed
    >
      <Box width={1} ref={setMeasuredHeight}>
        {children}
      </Box>
    </Box>
  );
};
