import * as React from 'react';
import type { ThemeProps } from '@mentimeter/ragnar-react';
import { withTheme } from '@mentimeter/ragnar-react';
import type { UseTransitionProps } from 'react-spring';
import { useTransition, animated, to } from 'react-spring';

export interface DrawerT extends ThemeProps {
  show: boolean;
  children: React.ReactNode;
  zIndex?: number;
  sticky?: boolean;
}

interface CreateDrawerProps {
  animation: UseTransitionProps;
  positioning: React.CSSProperties;
}

const createDrawerComponent = ({ animation, positioning }: CreateDrawerProps) =>
  withTheme<DrawerT>(({ show, sticky, zIndex, children, theme }) => {
    const transition = useTransition(show, {
      enter: { x: 0, y: 0 },
      ...animation,
    });
    return (
      <>
        {transition(
          (styles, show) =>
            show && (
              <animated.div
                role="dialog"
                aria-modal="true"
                aria-live="polite"
                style={{
                  position: sticky === true ? 'fixed' : 'absolute',
                  zIndex: zIndex !== undefined ? zIndex : theme.zIndex.drawer,
                  ...positioning,
                  transform: to(
                    [styles['x'], styles['y']],
                    (x, y) => `translate3d(${x}%, ${y}%, 0)`,
                  ),
                }}
              >
                {children}
              </animated.div>
            ),
        )}
      </>
    );
  });

export const DrawerLeft = createDrawerComponent({
  animation: {
    from: { x: -100, y: 0 },
    leave: { x: -100, y: 0 },
  },
  positioning: {
    left: 0,
    height: '100%',
  },
});

export const DrawerRight = createDrawerComponent({
  animation: {
    from: { x: 100, y: 0 },
    leave: { x: 100, y: 0 },
  },
  positioning: {
    right: 0,
    height: '100%',
  },
});

export const DrawerTop = createDrawerComponent({
  animation: {
    from: { x: 0, y: -100 },
    leave: { x: 0, y: -100 },
  },
  positioning: {
    top: 0,
    width: '100%',
  },
});

export const DrawerBottom = createDrawerComponent({
  animation: {
    from: { x: 0, y: 100 },
    leave: { x: 0, y: 100 },
  },
  positioning: {
    bottom: 0,
    width: '100%',
  },
});
