import * as React from 'react';
import type { BLOCKS, Block } from '@contentful/rich-text-types';
import { Box, Text } from '@mentimeter/ragnar-ui';
import type { ITable } from 'types/generated/contentful';
import type { JSX } from 'react';

export interface EmbeddedTableBlock extends Block {
  data: {
    target: ITable;
    content?: Array<unknown>;
    nodeType: BLOCKS.EMBEDDED_ENTRY;
  };
}

interface Props {
  table: EmbeddedTableBlock;
}

const TableRenderer = ({ table }: Props): JSX.Element => {
  const { tableData } = table.data.target.fields.table;
  const [head] = tableData;
  const rows = tableData.slice(1, tableData.length);
  return (
    <Box
      overflow="auto"
      width="100%"
      mx={['-24px', null, 0]}
      minWidth={['calc(100% + 48px)', null, 0]}
    >
      <Box as="table" display="table" width="100%" mb={[2, 4]}>
        <Box as="thead" display="table-header-group">
          <Box as="tr" display="table-row" width="100%">
            {head.map((tHeader: string) => (
              <Text
                as="th"
                py={2}
                px={3}
                textAlign="left"
                color="text"
                key={tHeader}
              >
                {tHeader}
              </Text>
            ))}
          </Box>
        </Box>
        <Box as="tbody" display="table-row-group">
          {rows.map((row: string[], rowIndex: number) => (
            <Box
              as="tr"
              display="table-row"
              width="100%"
              bg={rowIndex % 2 === 0 ? 'neutralWeakest' : undefined}
              key={`${row.toString()}-${rowIndex}`}
            >
              {row.map((cell, index) => (
                <Text
                  as="td"
                  py={2}
                  px={3}
                  textAlign="left"
                  key={`${cell}-${index}`}
                  color={rowIndex % 2 === 0 ? 'onNeutralWeakest' : undefined}
                >
                  {cell}
                </Text>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default TableRenderer;
