import { styled } from '@mentimeter/ragnar-styled';

const ImageStyled = styled('img')(
  'kosmosSpacing',
  'width',
  'height',
  'maxWidth',
  'maxHeight',
);

export type ImageT = React.ComponentProps<typeof ImageStyled>;

export const Image = (props: ImageT) => (
  <ImageStyled
    maxWidth="100%"
    maxHeight="100%"
    // Reset selector
    className="r-img"
    {...props}
  />
);
