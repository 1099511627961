import React, { useCallback, useState } from 'react';
import { Box, Button, Form, TextInputItem } from '@mentimeter/ragnar-ui';
import { gtmTrack } from '@mentimeter/google-tracking';
import { useTranslation } from 'react-i18next';
import { CrossIcon } from '@mentimeter/ragnar-visuals';
import { useMatch } from '@mentimeter/ragnar-device';
import { getRegionByVoteId } from '@mentimeter/region';
import { trackVisitor } from 'src/trackVisitor';
import { core } from '@mentimeter/http-clients';
import { trackUser } from '@api/tracking/client';
import { Narrow } from '../layout';
import { Clickable } from '../actions';
import { P } from '../typography';

const VOTING_URL = globalThis.__mentimeterEnv['NEXT_PUBLIC_VOTING_URL'];

export type VotingBarContext = 'Justvoted' | 'Homepage' | 'Signup';

interface Props {
  context: VotingBarContext;
}

const calculateBackgroundColor = (context: string) => {
  return context === 'Signup' || context === 'Justvoted' ? '#E7E8EB' : 'bg';
};

export const VotingBar = ({ context }: Props) => {
  const [state, setState] = useState<undefined | 'error' | 'loading'>();
  const { t } = useTranslation('common');
  const isDesktop = useMatch({ greaterThan: 2 });
  const votingBarRef = React.useRef<HTMLDivElement | null>(null);
  const [hideVotingBar, setHideVotingBar] = useState(false);

  const [rawVoteCode, setRawVoteCode] = React.useState<string>('');
  const voteId = rawVoteCode.replace(/\s/g, '');
  const isValidVotingId = voteId.length >= 4;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    void setRawVoteCode(event.target.value);

  const submitVoteCode = useCallback(
    async (voteId: string) => {
      setState('loading');
      try {
        const region = getRegionByVoteId(voteId);

        const { data, status } = await core({ region }).getSeriesByVoteId(
          voteId,
        );

        if (!data?.vote_key) {
          throw new Error('series missing in result');
        }

        if (status === 200) {
          gtmTrack({
            event: 'joinPresentation',
            type: data.closed_for_voting ? 'closed' : 'success',
          });

          trackVisitor({
            event: 'Clicked voting bar button',
            properties: { context, status: 'success' },
          });
          trackUser({
            event: 'Clicked voting bar button',
            properties: { context, status: 'success' },
          });
          window.location.assign(`${VOTING_URL}/${data.vote_key}`);
        }
      } catch {
        gtmTrack({
          event: 'joinPresentation',
          type: 'fail',
        });

        trackVisitor({
          event: 'Clicked voting bar button',
          properties: { context, status: 'error' },
        });
        setState('error');
        setTimeout(() => setState(undefined), 2000);
      }
    },
    [context],
  );

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>): void => {
      event.preventDefault();
      if (isValidVotingId) {
        submitVoteCode(voteId);
      }
    },
    [isValidVotingId, voteId, submitVoteCode],
  );

  if (hideVotingBar) return null;

  return (
    <Box
      width="100%"
      height={['120px', '106px']}
      bg={calculateBackgroundColor(context)}
    >
      <Box
        width="100%"
        height="106px"
        alignItems="center"
        justifyContent="center"
        px={[4, null, null, 6]}
        ref={votingBarRef}
        zIndex={9}
        extend={() => ({
          background: 'linear-gradient(70deg, #FFB9D0 50%, #FF80AB 50%)',
          borderBottomRightRadius: '2rem',
        })}
      >
        <Narrow alignItems="center" justifyContent="center">
          <Form
            display="flex"
            data-testid="enter-code-to-vote-form"
            bg="bg"
            height="auto"
            minHeight="72px"
            mt={[4, 0]}
            p={3}
            borderRadius={2}
            borderWidth={1}
            borderStyle="solid"
            borderColor="border"
            flexDirection={['column', 'row']}
            alignItems="center"
            justifyContent="center"
            onSubmit={onSubmit}
          >
            <P
              fontSize={['100', '112.5', '125']}
              lineHeight="body"
              mb={[2, 0]}
              mr={[0, 3]}
              textAlign="center"
              extend={() => ({
                whiteSpace: 'nowrap',
              })}
            >
              {isDesktop
                ? t('common:voting_bar_header.enter_code_to_vote')
                : t('common:voting_bar_header.enter_code_to_vote_mobile')}
            </P>
            <Box flexDirection="row">
              <TextInputItem
                aria-label={t('common:voting_bar_header.enter_code_here')}
                data-testid="enter-code-to-vote-input"
                type="tel"
                value={rawVoteCode}
                name="id"
                id="enter-vote-key"
                placeholder="1234 5678"
                onChange={handleChange}
                maxLength={12} // You can enter a max of 12 chars ("12 34 56 78 ")
                mr={2}
                inputSize="compact"
                extend={() => ({
                  maxWidth: '150px',
                })}
              />
              <Button
                data-testid="enter-code-to-vote-button"
                aria-label="Continue"
                type="submit"
                disabled={!isValidVotingId}
                state={state}
                variant="primary"
              >
                {t('common:voting_bar_header.join')}
              </Button>
            </Box>
          </Form>
        </Narrow>
        {isDesktop && (
          <Clickable
            position="absolute"
            right="2rem"
            aria-label={t('common:page_links.close_menu')}
            onClick={() => setHideVotingBar(true)}
          >
            <CrossIcon size={3} />
          </Clickable>
        )}
      </Box>
    </Box>
  );
};
