import * as React from 'react';
import type { ButtonT } from '@mentimeter/ragnar-ui';
import type { Document } from '@contentful/rich-text-types';
import { CalloutRenderer } from '../cms/CalloutRenderer';
import { Section } from '../ui/layout';
import { Button } from './actions';
import type { ThemeT } from './Theme';

export interface CalloutT extends ThemeT {
  body?: Document;
  action?: ButtonT | undefined;
}

export function Callout({ action, body, theme }: CalloutT) {
  const content = (
    <>
      {body && <CalloutRenderer document={body} />}
      {action && <Button variant="primary" {...action} />}
    </>
  );
  return (
    <Section
      py={4}
      px={[3, 5]}
      alignItems="center"
      theme={theme}
      extend={() => ({
        textAlign: 'center',
      })}
    >
      {content}
    </Section>
  );
}
