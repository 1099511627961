import * as React from 'react';
import { useMatch } from '@mentimeter/ragnar-device';
import { Box, type ButtonT } from '@mentimeter/ragnar-ui';
import { Primary, DrawerRight } from '@mentimeter/ragnar-ui';
import {
  CrossIcon,
  MentimeterLogoType,
  MenuIcon,
} from '@mentimeter/ragnar-visuals';
import { getMentiAcademyURL } from '@mentimeter/user';
import { useTranslation } from 'react-i18next';
import { usePathname } from '@mentimeter/next-navigation';
import { useHasMounted } from '@mentimeter/react-hooks';
import { trackVisitor } from 'src/trackVisitor';
import type { DisplayProperty } from '@mentimeter/ragnar-styled';
import { DEFAULT_LOCALE } from 'src/constants.cjs';
import { useVisitorSplits } from 'src/split-hooks';
import { VisitorExperiment } from 'src/components/experiments/VisitorExperiment';
import { useAppState } from '../../appState';
import { useReferralPropMapper } from '../../utils/useReferralPropMapper';
import { Button, Clickable } from '../actions';
import { Wide } from '../layout';
import type { Action } from './ActionCollapsable';
import ActionCollapsable from './ActionCollapsable';
import { ActionMenu } from './ActionMenu';
import { NavLink } from './NavLink';
import { type VotingBarContext } from './VotingBar';
import { VotingBar } from './VotingBar';
import { VotingBarV2 } from './VotingBarV2';

export interface MenuAction extends Action {
  referenceId?: string;
  subActions?: Action[];
}

// Remember to update static header as well
const useActions = (): MenuAction[] => {
  const { t, i18n } = useTranslation('common');
  const { language: locale } = i18n;
  const enLocale = DEFAULT_LOCALE === locale;
  const { OG_Nav_Enterprise } = useVisitorSplits(['OG_Nav_Enterprise']);

  const actions = [
    {
      name: t('common:page_links.work'),
      href: '/work',
      referenceId: 'work',
      subActions: [
        {
          name: t('common:page_links.employee_training'),
          href: '/work/training-and-onboarding',
        },
        {
          name: t('common:page_links.team_meetings'),
          href: '/work/team-meetings',
        },
        {
          name: t('common:page_links.team_building'),
          href: '/work/team-building',
        },
        {
          name: t('common:page_links.workshops'),
          href: '/work/workshops',
        },
        {
          name: t('common:page_links.events_conferences'),
          href: '/work/events',
        },
        {
          name: t('common:page_links.evaluations_retros'),
          href: '/work/evaluations-and-retros',
        },
        {
          name: t('common:page_links.stage_presentations'),
          href: '/work/stage-presentations',
        },
        {
          name: t('common:page_links.all_hands_meetings'),
          href: '/work/all-hands-meetings',
        },
        {
          name: t('common:page_links.hybrid_work'),
          href: '/work/hybrid-work',
        },
        {
          name: t('common:page_links.brainstorming'),
          href: '/work/brainstorming',
        },
      ],
    },
    {
      name: t('common:page_links.education'),
      href: '/education',
      referenceId: 'education',
      subActions: [
        {
          name: t('common:page_links.lectures'),
          href: '/education/lectures',
        },
        {
          name: t('common:page_links.assessments'),
          href: '/education/assessments',
        },
        {
          name: t('common:page_links.seminars'),
          href: '/education/seminars',
        },
        {
          name: t('common:page_links.student_events'),
          href: '/education/student-events',
        },
        {
          name: t('common:page_links.classroom_lessons'),
          href: '/education/classroom-lessons',
        },
        {
          name: t('common:page_links.hybrid_learning'),
          href: '/education/hybrid-learning',
        },
      ],
    },
    {
      name: t('common:page_links.features'),
      href: '/features',
      referenceId: 'features',
      subActions: [
        {
          name: t('common:page_links.ai_builder'),
          href: '/features/ai-builder',
        },
        {
          name: t('common:page_links.live_polling'),
          href: '/features/live-polling',
        },
        {
          name: t('common:page_links.word_cloud'),
          href: '/features/word-cloud',
        },
        {
          name: t('common:page_links.quiz'),
          href: '/features/quiz-presentations',
        },
        {
          name: t('common:page_links.q&a'),
          href: '/features/live-questions-and-answers',
        },
        {
          name: t('common:page_links.surveys'),
          href: '/features/surveys',
        },
        {
          name: t('common:page_links.presentation_maker'),
          href: '/features/presentation-maker',
        },
      ],
    },
    {
      name: t('common:page_links.resources'),
      hide: !enLocale,
      referenceId: 'resources',
      subActions: [
        {
          name: t('common:page_links.how_to'),
          href: '/how-to',
        },
        {
          name: t('common:page_links.menti_academy'),
          href: getMentiAcademyURL(
            '?utm_source=mentimeter.com&utm_medium=referral&utm_campaign=top+navigation',
          ),
          target: '_blank',
          rel: 'noopener noreferrer',
        },
        {
          name: t('common:page_links.templates'),
          href: '/templates',
        },
        {
          name: t('common:page_links.webinars'),
          href: '/webinars',
        },
        {
          name: t('common:page_links.blog'),
          href: '/blog',
        },
        {
          name: t('common:page_links.integrations'),
          href: '/integrations',
        },

        {
          name: t('common:page_links.stories'),
          href: '/stories',
        },
        {
          name: t('common:page_links.help_center'),
          href: 'https://help.mentimeter.com',
        },
      ],
    },
    {
      name: 'Enterprise-Grade',
      hide: !enLocale || OG_Nav_Enterprise !== 'v2',
      referenceId: 'enterprise-grade',
      subActions: [
        {
          name: 'Enterprise',
          href: '/enterprise',
        },
        {
          name: 'Education',
          href: '/education/enterprise',
        },

        {
          name: 'Customer stories',
          href: '/stories',
        },
        {
          name: 'Trust center',
          href: '/trust',
        },
      ],
    },
    { name: t('common:page_links.pricing'), href: '/plans' },
    {
      name: t('common:page_links.get_enterprise'),
      href: '/enterprise',
      onClick: () =>
        trackVisitor({
          event: 'Clicked header to enterprise',
        }),
    },
  ].filter(({ hide }) => !hide);

  return actions;
};

export const HeaderButton = (buttonProps: ButtonT) => {
  const { user, userFinishedLoading } = useAppState();
  const referralPropMapper = useReferralPropMapper();
  const { t } = useTranslation('common');

  if (!userFinishedLoading) {
    return (
      <Button
        {...buttonProps}
        variant="brand"
        data-testid="header-button-loading"
        id="header-button-loading"
        state="loading"
        width={120}
      >
        Loading...
      </Button>
    );
  }

  if (user) {
    return (
      <Button
        {...buttonProps}
        href="/app"
        variant="brand"
        data-testid="header-home"
        id="header-home"
      >
        {t('common:page_links.home')}
      </Button>
    );
  }

  return (
    <Button
      {...buttonProps}
      variant="brand"
      data-testid="header-signup"
      {...referralPropMapper({ href: '/auth/signup' })}
    >
      {t('common:page_links.sign_up')}
    </Button>
  );
};

export const HeaderWrapper = ({
  offset = 0,
  children,
}: {
  offset?: number;
  children: React.ReactNode;
}) => (
  <Box
    position="sticky"
    top={offset}
    zIndex={10}
    width="100%"
    alignItems="center"
    justifyContent="center"
    px={3}
    py={[3, null, 4]}
    bg="bg"
    height={4}
    borderBottomWidth="1px"
    borderStyle="solid"
    borderColor="borderWeak"
    as="nav"
  >
    {children}
  </Box>
);

interface Props {
  showVotingBar?: boolean;
  context?: VotingBarContext;
}

export function Header({ showVotingBar = false, context = 'Homepage' }: Props) {
  const [showMenu, setShowMenu] = React.useState<boolean>(false);
  const pathname = usePathname();
  const actions = useActions();
  const { t } = useTranslation('common');
  const isDesktop = useMatch({ greaterThan: 2 });

  const { userFinishedLoading, user } = useAppState();

  React.useEffect(() => {
    setShowMenu(false);
  }, [pathname]);

  const mountBothMenus = !useHasMounted();
  const mountDesktopMenu = mountBothMenus || isDesktop;
  const mountMobileMenu = mountBothMenus || !isDesktop;

  const displayOnDesktop = ['none', 'none', 'flex'];
  const displayOnMobile = ['flex', 'flex', 'none'] as DisplayProperty[];

  return (
    <>
      {!isDesktop && (
        <MobileMenu
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          actions={actions}
        />
      )}

      <HeaderWrapper>
        <Wide
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          zIndex={10}
        >
          <Box flex="1">
            <Clickable
              href="/"
              data-testid="page-header-logo"
              aria-label={t('common:page_links.to_mentimeter_aria')}
              overflow="visible"
              display="block"
            >
              <MentimeterLogoType width={200} />
            </Clickable>
          </Box>

          {mountDesktopMenu && (
            <>
              <Box
                flex="2"
                display={displayOnDesktop}
                as="ul"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <ActionMenu
                  actions={actions}
                  overviewLabel={t('common:page_links.overview')}
                />
              </Box>

              <Box
                flex="1"
                display={displayOnDesktop}
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                {userFinishedLoading && user === null && (
                  <NavLink
                    aria-label={t('common:page_links.login_aria')}
                    href="/auth/login"
                    data-testid="page-header-login"
                    active={false}
                  >
                    {t('common:page_links.login')}
                  </NavLink>
                )}

                <HeaderButton ml={3} />
              </Box>
            </>
          )}

          {mountMobileMenu && (
            <Clickable
              display={displayOnMobile}
              aria-label={t('common:basic.menu')}
              data-testid="page-header-menu"
              onClick={() => setShowMenu(!showMenu)}
              id="header-menuButton"
            >
              <MenuIcon size={5} color="primary" />
            </Clickable>
          )}
        </Wide>
      </HeaderWrapper>

      {showVotingBar && (
        <VisitorExperiment
          splitId="OG_Voting_Bar_Redesign"
          variants={{
            control: VotingBar,
            v1: VotingBar,
            v2: VotingBarV2,
          }}
        />
      )}
    </>
  );
}

interface MobileMenuProps {
  showMenu: boolean;
  setShowMenu: (value: boolean) => void;
  actions: MenuAction[];
}

const MobileMenu = ({ showMenu, setShowMenu, actions }: MobileMenuProps) => {
  const { t } = useTranslation('common');
  const { user, userFinishedLoading } = useAppState();

  React.useEffect(() => {
    document.body.style.overflow = showMenu ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showMenu]);

  return (
    <Box as="nav">
      <DrawerRight show={showMenu} sticky>
        <Primary>
          <Box
            flex={1}
            bg="bg"
            width="100vw"
            height="100%"
            px={3}
            py={[3, null, 4]}
            data-testid="page-header-menu-container"
            alignItems="stretch"
            overflow="scroll"
          >
            <Box alignItems="stretch">
              <Box position="fixed" right={0} mr={3} zIndex={1}>
                <Box>
                  <Clickable
                    aria-label={t('common:page_links.close_menu')}
                    onClick={() => setShowMenu(false)}
                  >
                    <CrossIcon size={5} color="primary" />
                  </Clickable>
                </Box>
              </Box>
              <Box px={2} mb={4} mt="72px">
                {actions.map((a) => (
                  <ActionCollapsable
                    action={a}
                    actions={a.subActions ? a.subActions : undefined}
                    overviewLabel={t('common:page_links.overview')}
                    openDropdownLabel={`${t('common:page_links.dropdown')} - ${
                      a.name
                    }`}
                    key={a.name}
                    setShowMenu={setShowMenu}
                  />
                ))}
              </Box>
            </Box>
            <Box width={1} alignItems="center">
              {userFinishedLoading && user === null && (
                <Button
                  aria-label={t('common:page_links.login_aria')}
                  data-testid="page-header-login"
                  href="/auth/login"
                  size="large"
                  width={1}
                  variant="secondary"
                >
                  {t('common:page_links.login')}
                </Button>
              )}
              <HeaderButton mt={2} size="large" width={1} />
            </Box>
          </Box>
        </Primary>
      </DrawerRight>
    </Box>
  );
};

export default Header;
