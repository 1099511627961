import React from 'react';
import type { Rule } from '@mentimeter/ragnar-styled';
import {
  styled,
  LAYOUT,
  POSITION,
  FLEX_CONTAINER,
} from '@mentimeter/ragnar-styled';
import { combineRules } from '@mentimeter/ragnar-react';
import type { As } from '@mentimeter/ragnar-dsc';
import type { ComponentProps } from 'react';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { rule as boxRule } from '../Box';

type ListType = '1' | 'A' | 'a' | 'I' | 'i' | 'none';

const listRule: Rule<{ as?: As; type?: ListType }> = ({ as, type, theme }) => ({
  type,
  '> ul': {
    marginLeft: `${theme.kosmosSpacing.space2}px`,
  },
  '> li > ul': {
    marginLeft: `${theme.space[2]}px`,
  },
});

const FelaList = styled('ul', { displayName: 'List' })(
  combineRules(listRule, boxRule),
  ...[...LAYOUT, ...FLEX_CONTAINER, ...POSITION],
  'color',
  'kosmosSpacing',
);

export type ListT = ComponentProps<typeof FelaList>;

export const List = React.forwardRef((props: ListT, ref) => {
  const { className, as = 'ul', type, ...rest } = props;

  const MAP_LIST_TYPE = {
    '1': 'list-dec',
    A: 'list-upper-alpha',
    a: 'list-lower-alpha',
    I: 'list-upper-roman',
    i: 'list-lower-roman',
    none: 'list-none',
  };

  const defaultClasses = [
    'r-box', // Reset selector
    'flex',
    'flex-col',
    'flex-none',
    'items-start',
    'min-w-0',
    'min-h-0',
    'relative',
    'w-auto',
    'list-inside',
    type && MAP_LIST_TYPE[type],
  ];

  return (
    <FelaList
      as={as}
      ref={ref}
      className={cn(defaultClasses, className)}
      {...rest}
    />
  );
});
